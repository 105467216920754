import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeRadioOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiRadio'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        color: '#777788',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-checked': {
          color: '#7543FF',
        },
      },
    },
  };
};
