import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeMenuItemOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiMenuItem'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        color: '#FFF',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '19px',
        '&.Mui-selected': {
          background: 'rgba(255, 255, 255, 0.08) !important',
        },
      },
    },
  };
};
