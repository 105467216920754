import { AppPalette } from '@core/theme/types/main';

export const initializeTreeViewOverrides = (palette: AppPalette, config?: any): any => {
  return {
    ...config,
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  };
};
