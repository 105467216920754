import { t } from '@lingui/macro';
import { PhoneConverter } from '@shared/utils/phone-converter';
import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from 'class-validator';

@ValidatorConstraint({ name: 'phoneNumber', async: false })
export class PhoneNumber implements ValidatorConstraintInterface {
  validate(text: string, args: ValidationArguments) {
    const phoneConverter = new PhoneConverter();
    return phoneConverter.isValidNumber(text);
  }

  defaultMessage(args: ValidationArguments) {
    return t`Invalid phone format`;
  }
}
