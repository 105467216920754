import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeListOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiList'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        borderRadius: '12px',
        background: 'linear-gradient(0deg, #8C8C8C 0%, #8C8C8C 100%), #1E1D36',
        backgroundBlendMode: 'overlay, normal',
      },
    },
  };
};
