import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeSelectOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiSelect'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        borderRadius: '15px',
        background: 'rgba(255, 255, 255, 0.08)',
        color: '#FFF',
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '19px',
        height: '26px',
        width: '100%',
        minWidth: '67px',

        '& .MuiOutlinedInput-notchedOutline': {
          borderStyle: 'hidden',
        },
      },
      select: {
        overflow: 'unset',
      },
    },
  };
};
