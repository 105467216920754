import { AppPalette } from '@core/theme/types/main';

export const initializeTreeItemOverrides = (palette: AppPalette, config?: any): any => {
  return {
    ...config,
    styleOverrides: {
      root: {},
      iconContainer: {
        position: 'absolute',
        right: '14px',
      },
      group: {
        marginLeft: '12px',
      },
      content: {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
        '&.Mui-selected:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
          backgroundColor: 'transparent',
        },
        '&.Mui-selected.Mui-focused': {
          backgroundColor: 'transparent',
        },
      },
    },
  };
};
