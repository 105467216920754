import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeInputBaseOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiInputBase'],
) => {
  return {
    ...config,
    root: {},
    styleOverrides: {
      root: {},
    },
  };
};
