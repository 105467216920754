import { initializeZIndex } from '@core/theme/units/zIndex';

import { AppThemeOptions } from './types/main';
import { initializeBreakpoints } from './units/breakpoints';
import { initializeDirection } from './units/direction';
import { initializeMixins } from './units/mixins';
import { initializeOverrides } from './units/overrides';
import { initializePalette } from './units/palette';
import { initializeShape } from './units/shape';
import { initializeSpacing } from './units/spacing';
import { initializeTypography } from './units/typography';
import { appCreateTheme as appCreateThemeCore } from './utils/theme';

export function appCreateTheme(options?: AppThemeOptions) {
  const palette = initializePalette(options?.palette);

  const theme = appCreateThemeCore({
    breakpoints: initializeBreakpoints(options?.breakpoints),
    direction: initializeDirection(options?.direction),
    mixins: initializeMixins(options?.mixins),
    components: initializeOverrides(palette, options?.components),
    zIndex: initializeZIndex(options?.zIndex),
    shape: initializeShape(options?.shape),
    spacing: initializeSpacing(options?.spacing),
    typography: initializeTypography(options?.typography),
  });

  return theme;
}
