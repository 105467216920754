import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const initializeFormHelperText = (
  palette: AppPalette,
  config?: Components<Theme>['MuiFormHelperText'],
): any => {
  return {
    ...config,
    styleOverrides: {
      root: {
        position: 'absolute',
        bottom: -15,
        '&.Mui-error': {
          color: _var(CSS_VARIABLES.colorError500),
        },
      },
    },
  };
};
