import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeDrawerOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiDrawer'],
) => {
  return {
    ...config,
    styleOverrides: {
      paper: {
        height: '85%',
        borderRadius: '21px 21px 0px 0px',
        borderTop: '2px solid #7543FF',
        background: '#161524',
        backdropFilter: 'blur(50px)',
      },
    },
  };
};
