import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const initializeDialogContentOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiDialogContent'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        padding: '8px 24px',
        fontFamily: _var(CSS_VARIABLES.fontFamily),
        lineHeight: '1.43',
      },
    },
  };
};
