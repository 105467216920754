import { AppPalette } from '@core/theme/types/main';
import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const initializeBreadcrumbsOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiBreadcrumbs'],
) => {
  return {
    ...config,
    root: {},
    styleOverrides: {
      li: {
        '& a': {
          color: '#fff',
          fontSize: '16px',
          fontWeight: 400,
        },
      },
      separator: {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 400,
      },
    },
  };
};
