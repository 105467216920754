import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeToolipOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiTooltip'],
) => {
  return {
    ...config,
    styleOverrides: {
      tooltip: {
        backgroundColor: '#473763',
        color: '#FFF',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.3px',
      },
      tooltipPlacementRight: {
        marginLeft: '-25px !important',
      },
    },
  };
};
