import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeLinkOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiLink'],
) => {
  return {
    ...config,
    root: {},
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        fontSize: '11px',
        fontWeight: 700,
        lineHeight: '15px',
        textDecoration: 'none',
      },
      underlineAlways: {
        color: palette.text.primary,
      },
    },
  };
};
