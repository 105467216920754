import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeTextFieldOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiTextField'],
) => {
  return {
    ...config,
  };
};
