import { t } from '@lingui/macro';
import { appIsArray } from '@shared/utils/array';
import { appIsString } from '@shared/utils/string';
import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs, { ValidatorStatic } from 'validatorjs';

export interface Validation {
  function: (value: string | number | Array<unknown> | boolean, attribute?: string) => boolean;
  message: string;
}

export const validationRegExpr = {
  oneLowerCase: /[a-z]/,
  oneUpperCase: /[A-Z]/,
  oneDigital: /[0-9]/,
  specialChar: /[!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  specialAndDigitChar: /[0-9!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  minimumLength: /^.{8,}$/,
  whiteSpace: /^(?![\s]).*[\S]+$/,
  phone: /^\+[0-9']{11,15}$/,
};

function createValidations<ClassKey extends string>(x: Record<ClassKey, Validation>) {
  return x;
}

export enum CustomValidationNames {
  passwordStrength = 'passwordStrength',
  whiteSpace = 'whiteSpace',
  phone = 'phone',
  samePass = 'samePass',
  array = 'array',
  number = 'number',
  positive = 'positive',
  integer = 'integer',
}

export function getDefaultValidations(customValidations?: { [key: string]: Validation }) {
  const baseValidations = createValidations({
    [CustomValidationNames.passwordStrength]: {
      function(value) {
        const formatValidators = [
          validationRegExpr.oneLowerCase,
          validationRegExpr.oneUpperCase,
          validationRegExpr.oneDigital,
          validationRegExpr.specialChar,
          validationRegExpr.minimumLength,
          validationRegExpr.whiteSpace,
        ];

        return typeof value === 'string'
          ? formatValidators.every((rule) => rule.test(value))
          : false;
      },
      message: t`Your password needs to meet the requirements`,
    },
    [CustomValidationNames.whiteSpace]: {
      function(value) {
        return appIsString(value) ? validationRegExpr.whiteSpace.test(value) : false;
      },
      message: t`Please remove blank spaces`,
    },
    [CustomValidationNames.phone]: {
      function(value) {
        return appIsString(value) ? validationRegExpr.phone.test(value) : false;
      },
      message: t`Invalid phone format`,
    },
    [CustomValidationNames.samePass]: {
      function(value, attribute) {
        if (attribute) {
          const newValue = (this as unknown as Form).validator.input[attribute];

          return newValue === value;
        }

        return false;
      },
      message: t`Passwords need to match`,
    },
    [CustomValidationNames.array]: {
      function(value) {
        return appIsArray(value) ? Boolean(value.length) : false;
      },
      message: t`This field is required.`,
    },
    [CustomValidationNames.number]: {
      function(value) {
        return !isNaN(Number(value));
      },
      message: t`Invalid number format.`,
    },
    [CustomValidationNames.positive]: {
      function(value) {
        return Number(value) > 0;
      },
      message: t`This field should be positive.`,
    },
    [CustomValidationNames.integer]: {
      function(value) {
        return Number.isInteger(Number(value));
      },
      message: t`This field should be integer.`,
    },
  });

  validatorjs.setMessages('en', {
    required: t`This field is mandatory`,
    email: t`Wrong email format`,
    max: t`This value is too long. It should have :max characters or less.`,
  });

  const rules: { [key: string]: Validation } = {
    ...baseValidations,
    ...customValidations,
  };

  return {
    dvr: dvr({
      package: validatorjs,
      extend: ({ validator }: { validator: ValidatorStatic }) => {
        Object.keys(rules).forEach((key) => {
          const config = rules[key];

          if (config) {
            validator.register(key, config.function, config.message);
          }
        });
      },
    }),
  };
}
