import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeBackdropOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiBackdrop'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        backdropFilter: 'blur(12px)',
        backgroundColor: 'transparent',
      },
    },
  };
};
