import { AppBreakpointsOptions } from '@core/theme/types/main';

export const initializeBreakpoints = (config?: AppBreakpointsOptions): AppBreakpointsOptions => {
  return {
    ...config,
    values: {
      xs: 0,
      sm: 500,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  };
};
