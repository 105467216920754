import { appInject, appInjectable } from '@core/di/utils';
import { t } from '@lingui/macro';
import { TokenRefreshStatus } from '@shared/constants/auth';
import { DI_TOKENS } from '@shared/constants/di';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import {
  HttpInstance,
  HttpRequestConfig,
  HttpConfig,
  HttpSuccessResponse,
  HttpFailResponse,
  IHttpClientService,
} from '@shared/types/http-client';
import { INotificationService } from '@shared/types/notification-service';
import { parseArrayBuffer } from '@shared/utils/binary';
import { browser } from '@shared/utils/browser';
import Axios from 'axios';

@appInjectable()
export class HttpClientService implements IHttpClientService {
  private _client: HttpInstance;
  private getAccessToken?: HttpConfig['getAccessToken'];
  private refreshToken?: HttpConfig['refreshToken'];
  private getTokenRefreshStatus?: HttpConfig['getTokenRefreshStatus'];
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);

  constructor() {
    this._client = this.createClient();
    this.setClientResponseInterceptor();
    this.setClientRequestInterceptor();
  }

  setConfig: IHttpClientService['setConfig'] = (config) => {
    this.setClientConfig(config.defaults);
    this.refreshToken = config.refreshToken;
    this.getAccessToken = config.getAccessToken;
    this.getTokenRefreshStatus = config.getTokenRefreshStatus;
  };

  async get<T = unknown>(url: string, config?: HttpRequestConfig): Promise<HttpSuccessResponse<T>> {
    return this._client.get<T>(url, config);
  }

  post<T = unknown>(
    url: string,
    body?: unknown,
    config?: HttpRequestConfig,
  ): Promise<HttpSuccessResponse<T>> {
    return this._client.post<T>(url, body, config);
  }

  put<T = unknown>(
    url: string,
    body?: unknown,
    config?: HttpRequestConfig,
  ): Promise<HttpSuccessResponse<T>> {
    return this._client.put<T>(url, body, config);
  }

  patch<T = unknown>(
    url: string,
    body?: unknown,
    config?: HttpRequestConfig,
  ): Promise<HttpSuccessResponse<T>> {
    return this._client.patch<T>(url, body, config);
  }

  delete<T = unknown>(url: string, config?: HttpRequestConfig): Promise<HttpSuccessResponse<T>> {
    return this._client.delete<T>(url, config);
  }

  generateCancelToken: IHttpClientService['generateCancelToken'] = () => {
    return Axios.CancelToken.source();
  };

  private createClient = () => {
    return Axios.create();
  };

  private get authHeader() {
    if (this.getAccessToken) {
      const accessToken = this.getAccessToken();

      return `Bearer ${accessToken}`;
    }

    return undefined;
  }

  private setClientConfig(defaults?: HttpConfig['defaults']) {
    if (defaults) {
      this.setDefaults(defaults);
    }
  }

  private setDefaults(defaults: HttpRequestConfig) {
    this._client.defaults.baseURL = defaults.baseURL;

    this._client.defaults.headers = {
      'Content-Type': 'application/json',
    };

    if (browser?.name === 'ie') {
      this._client.defaults.headers.Pragma = 'no-cache';
    }
  }

  private handleUnauthenticated = async (response: HttpSuccessResponse<unknown>) => {
    if (this.refreshToken) {
      await this.refreshToken();
    }

    if (!this.authHeader) {
      this.notificationsService.showError(t`Please log in to use the aplication`);
      return;
    }

    response.config.headers.Authorization = this.authHeader;

    return this._client(response.config);
  };

  private setClientResponseInterceptor() {
    this._client.interceptors.response.use(
      (response: HttpSuccessResponse<{ data: unknown }>) => {
        return { ...response, data: response.data };
      },
      async (error: HttpFailResponse<unknown>) => {
        if (!navigator.onLine) {
          this.notificationsService.showError(t`No or Poor internet connection`);
          return;
        }

        const response = error ? error.response : undefined;

        if (!response) {
          return;
        }

        if (response.status === 401) {
          this.handleUnauthenticated(response);
        }

        if (response.status >= 500) {
          this.notificationsService.showError(t`Something went wrong, please try again`);
        }

        let responseData = response.data;

        if (error?.request?.responseType === 'arraybuffer') {
          responseData = parseArrayBuffer(response.data as ArrayBuffer);
        }

        if (!responseData || !responseData.hasOwnProperty('status')) {
          throw HttpErrorResponse.createHttpErrorResponse({
            status: response.status,
          });
        } else {
          throw HttpErrorResponse.createHttpErrorResponse(responseData);
        }
      },
    );
  }

  private setClientRequestInterceptor() {
    this._client.interceptors.request.use(
      (config: HttpRequestConfig): HttpRequestConfig => {
        if (!this.getAccessToken || !this.getTokenRefreshStatus) {
          return config;
        }

        const accessToken = this.getAccessToken();

        if (!accessToken || this.getTokenRefreshStatus() === TokenRefreshStatus.refreshing) {
          return config;
        }

        config.headers.Authorization = this.authHeader;

        return config;
      },
      (error: Error) => {
        throw error;
      },
    );
  }
}
