import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializePaperOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiPaper'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        background: 'inherit',
      },
    },
  };
};
