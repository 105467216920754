import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeInputLabelOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiInputLabel'],
) => {
  return {
    ...config,
    root: {},
    styleOverrides: {
      root: {
        color: 'rgba(255, 255, 255, 0.60)',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.2px',
      },
    },
  };
};
