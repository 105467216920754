import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const initializeButtonOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiButton'],
) => {
  return {
    ...config,
    styleOverrides: {
      root: {
        textDecoration: 'none !important',
        padding: '10px 15px',
        '&$disabled': {
          color: `${_var(CSS_VARIABLES.disabledColor)} !important`,
        },
        fontWeight: 700,
        fontSize: '11px',
        lineHeight: '14.51px',
      },
      sizeSmall: {
        padding: '8px 15px',
      },
      outlined: {
        padding: '10px 15px',
      },
      text: {
        padding: '10px 15px',
      },

      textSecondary: {
        color: _var(CSS_VARIABLES.controlLightColor),

        '&:hover': {
          backgroundColor: '#EDF0FF',
        },

        '& span': {
          color: _var(CSS_VARIABLES.accentColor),
        },

        '&:hover span': {
          color: _var(CSS_VARIABLES.accentColor),
        },
      },

      textPrimary: {
        color: _var(CSS_VARIABLES.textTitleColor),

        '&:hover': {
          backgroundColor: _var(CSS_VARIABLES.controlDarkColorHoverBackground),
        },

        '&:hover span': {
          color: _var(CSS_VARIABLES.textTitleColor),
        },
      },

      outlinedPrimary: {
        width: '100%',
        height: '56px',
        borderRadius: '12px',
        background: 'transparent',
        border: `1px solid #7543FF !important`,
        color: _var(CSS_VARIABLES.colorAdditionalWhite),
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '160%',
        letterSpacing: '0.2px',
        textTransform: 'none',

        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.60)',
          backgroundColor: 'rgba(107, 57, 244, 0.30)',
        },
      } as any,

      outlinedSecondary: {
        width: '100%',
        color: _var(CSS_VARIABLES.controlDarkColor),
        border: `1px solid ${_var(CSS_VARIABLES.controlDarkColor)}`,

        '&:hover': {
          backgroundColor: _var(CSS_VARIABLES.controlDarkColorHoverBackground),
          borderColor: _var(CSS_VARIABLES.controlDarkColor),
        },
        '&:hover span': {
          color: _var(CSS_VARIABLES.controlDarkColorHover),
        },
      },

      containedPrimary: {
        width: '100%',
        height: '56px',
        borderRadius: '12px',
        backgroundColor: _var(CSS_VARIABLES.colorSecondary200),
        color: _var(CSS_VARIABLES.colorAdditionalWhite),
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '160%',
        letterSpacing: '0.2px',
        textTransform: 'none',

        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.60)',
          backgroundColor: 'rgba(107, 57, 244, 0.30)',
        },

        '&:hover': {
          backgroundColor: _var(CSS_VARIABLES.colorSecondary200),
        },
      } as any,
    },
  };
};
