import { AppPalette } from '@core/theme/types/main';
import { initializeBackdropOverrides } from '@core/theme/units/overrides/backdrop';
import { initializeBreadcrumbsOverrides } from '@core/theme/units/overrides/breadcrumbs';
import { initializeDialogContentOverrides } from '@core/theme/units/overrides/dialog-content';
import { initializeDrawerOverrides } from '@core/theme/units/overrides/drawer';
import { initializeFormControlOverrides } from '@core/theme/units/overrides/form-control';
import { initializeInputBaseOverrides } from '@core/theme/units/overrides/input-base';
import { initializeLinkOverrides } from '@core/theme/units/overrides/link';
import { initializeListOverrides } from '@core/theme/units/overrides/list';
import { initializeMenuItemOverrides } from '@core/theme/units/overrides/menu-item';
import { initializeOutlinedInputOverrides } from '@core/theme/units/overrides/outlined-input';
import { initializePaperOverrides } from '@core/theme/units/overrides/paper';
import { initializeRadioOverrides } from '@core/theme/units/overrides/radio';
import { initializeSelectOverrides } from '@core/theme/units/overrides/select';
import { initializeToolipOverrides } from '@core/theme/units/overrides/tooltip';
import { initializeTreeItemOverrides } from '@core/theme/units/overrides/tree-item';
import { initializeTreeViewOverrides } from '@core/theme/units/overrides/tree-view';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { initializeButtonOverrides } from './button';
import { initializeCircilarProgressOverrides } from './circular-progress';
import { initializeCssBaselineOverrides } from './css-baseline';
import { initializeFormHelperText } from './form-helper-text';
import { initializeInputLabelOverrides } from './input-label';
import { initializeTextFieldOverrides } from './text-field';

export const initializeOverrides = (
  palette: AppPalette,
  config?: Components<Omit<Theme, 'components'>>,
) => {
  return {
    MuiCssBaseline: initializeCssBaselineOverrides(config?.MuiCssBaseline),
    MuiButton: initializeButtonOverrides(palette, config?.MuiButton),
    MuiTextField: initializeTextFieldOverrides(palette, config?.MuiTextField),
    MuiLink: initializeLinkOverrides(palette, config?.MuiLink),
    MuiBreadcrumbs: initializeBreadcrumbsOverrides(palette, config?.MuiBreadcrumbs),
    MuiFormHelperText: initializeFormHelperText(palette, config?.MuiFormHelperText),
    MuiDialogContent: initializeDialogContentOverrides(palette, config?.MuiDialogContent),
    MuiFormControl: initializeFormControlOverrides(palette, config?.MuiFormControl),
    MuiOutlinedInput: initializeOutlinedInputOverrides(palette, config?.MuiOutlinedInput),
    MuiInputBase: initializeInputBaseOverrides(palette, config?.MuiInputBase),
    MuiInputLabel: initializeInputLabelOverrides(palette, config?.MuiInputLabel),
    MuiCircularProgress: initializeCircilarProgressOverrides(palette, config?.MuiCircularProgress),
    MuiBackdrop: initializeBackdropOverrides(palette, config?.MuiBackdrop),
    MuiDrawer: initializeDrawerOverrides(palette, config?.MuiDrawer),
    MuiTreeView: initializeTreeViewOverrides(palette, config),
    MuiTreeItem: initializeTreeItemOverrides(palette, config),
    MuiSelect: initializeSelectOverrides(palette, config?.MuiSelect),
    MuiPaper: initializePaperOverrides(palette, config?.MuiPaper),
    MuiList: initializeListOverrides(palette, config?.MuiList),
    MuiMenuItem: initializeMenuItemOverrides(palette, config?.MuiMenuItem),
    MuiRadio: initializeRadioOverrides(palette, config?.MuiRadio),
    MuiTooltip: initializeToolipOverrides(palette, config?.MuiTooltip),
    ...config,
  };
};
