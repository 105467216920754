import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { Config, IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';

@appInjectable()
export class ConfigService implements IConfigService {
  private config: Config;
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.configHttpClientService);

  initialize() {
    return this.setConfig();
  }

  get() {
    return this.config;
  }

  get baseUrlWallets() {
    const wallets = this.config.apiURLs.wallets;
    return `${wallets.origin}${wallets.prefix}`;
  }

  get baseUrlOrders() {
    const orders = this.config.apiURLs.orders;
    return `${orders.origin}${orders.prefix}`;
  }

  get baseUrlCards() {
    const cards = this.config.apiURLs.cards;
    return `${cards.origin}${cards.prefix}`;
  }

  get baseUrlRates() {
    const orders = this.config.apiURLs.orders;
    return `${orders.origin}${orders.prefix}`;
  }

  get baseUrlUsers() {
    const users = this.config.apiURLs.users;
    return `${users.origin}${users.prefix}`;
  }

  get baseUrlWorldpay() {
    const worldpay = this.config.apiURLs.worldpay;
    return `${worldpay.origin}${worldpay.prefix}`;
  }

  worldpayCheckoutSDKUrl(): string | null {
    if (this.config.worldpayCheckoutSDK && this.config.worldpayCheckoutSDK.checkoutSDKUrl) {
      return this.config.worldpayCheckoutSDK.checkoutSDKUrl;
    } else {
      return null;
    }
  }

  worldpayCheckoutSDKId(): string | null {
    if (this.config.worldpayCheckoutSDK && this.config.worldpayCheckoutSDK.checkoutSDKId) {
      return this.config.worldpayCheckoutSDK.checkoutSDKId;
    } else {
      return null;
    }
  }

  isActiveGooglePay(): boolean {
    if (this.config.googlePay?.isActive) {
      return this.config.googlePay.isActive;
    } else {
      return false;
    }
  }

  googlePayEnvironment(): string {
    if (this.config.googlePay?.environment) {
      return this.config.googlePay.environment;
    } else {
      return 'TEST';
    }
  }

  googlePayGateway(): string | null {
    if (this.config.googlePay?.tokenizationSpecificationGateway) {
      return this.config.googlePay.tokenizationSpecificationGateway;
    } else {
      return null;
    }
  }

  googlePayGatewayMerchantId(): string | null {
    if (this.config.googlePay?.tokenizationSpecificationGatewayMerchantId) {
      return this.config.googlePay.tokenizationSpecificationGatewayMerchantId;
    } else {
      return null;
    }
  }

  googlePayMerchantName(): string | null {
    if (this.config.googlePay?.merchantName) {
      return this.config.googlePay.merchantName;
    } else {
      return null;
    }
  }

  googlePayMerchantId(): string | null {
    if (this.config.googlePay?.merchantId) {
      return this.config.googlePay.merchantId;
    } else {
      return null;
    }
  }

  get landingURL() {
    return this.config.app.landingURL;
  }

  get termsURL() {
    return this.config.app.termsURL;
  }

  get maxOrderSize() {
    return this.config.orders.maxOrderSize;
  }

  get orderConfirmationTimeMinutes() {
    return this.config.orders.orderConfirmationTimeMinutes;
  }

  get authConfig() {
    return this.config.auth;
  }

  get paymentProviderKey() {
    return this.config.paymentProvider.publicKey;
  }

  get filteringFromYear() {
    return this.config.filtering.startYear;
  }

  get mfaDelayMinutes() {
    return this.config.mfa.codeDelayMinutes;
  }

  get ratesRefreshInterval() {
    return this.config.rates.refreshIntervalMinutes;
  }

  get perPageOptions() {
    return this.config.pagination.perPage;
  }

  get version() {
    return this.config.version;
  }

  get blockchainViewerOrigin() {
    return this.config.blockchainViewerOrigin;
  }

  private async setConfig() {
    this.httpClient.setConfig({
      defaults: {
        baseURL: window.location.origin,
      },
    });

    const { data } = await this.httpClient.get<Config>('./public/config.json');
    this.config = data;
  }

  get transactionFee(): number {
    return this.config.transactionFee || 1.5;
  }

  get isAvailableRegistration(): boolean {
    return this.config.isAvailableRegistration || false;
  }

  get supportEmail() {
    return this.config.support_email || 'admin@wealthstack.com';
  }
}
