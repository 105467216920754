import { validationRegExpr } from '@core/forms/validations';
import { t } from '@lingui/macro';
import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from 'class-validator';

@ValidatorConstraint({ name: 'passwordStrength', async: false })
export class PasswordStrength implements ValidatorConstraintInterface {
  validate(text: string, args: ValidationArguments) {
    return (
      text.length >= 8 &&
      validationRegExpr.oneUpperCase.test(text) &&
      validationRegExpr.oneLowerCase.test(text) &&
      validationRegExpr.oneDigital.test(text) &&
      validationRegExpr.specialChar.test(text)
    );
  }

  defaultMessage(args: ValidationArguments) {
    return t`Your password needs to meet the requirements`;
  }
}
