import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeFormControlOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiFormControl'],
) => {
  return {
    ...config,
    root: {},
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  };
};
