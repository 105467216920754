import { AppThemeOptions, AppTypographyOptions } from '@core/theme/types/main';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const initializeTypography = (
  config?: AppThemeOptions['typography'],
): AppTypographyOptions => {
  return {
    ...config,
    fontFamily: _var(CSS_VARIABLES.fontFamily),

    h1: {
      fontSize: '48px',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      fontWeight: 500,
      lineHeight: '130%',
      letterSpacing: '-0.5px',
    },
    h2: {
      fontSize: '40px',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      fontWeight: 500,
      lineHeight: '140%',
      letterSpacing: '0.2px',
    },
    h3: {
      fontSize: '28px',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      fontWeight: 500,
      lineHeight: '150%',
    },
    h4: {
      fontSize: '24px',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      fontWeight: 500,
      lineHeight: '150%',
    },
    h5: {
      fontSize: '20px',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      fontWeight: 500,
      lineHeight: '150%',
    },
    h6: {
      fontSize: '16px',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      fontWeight: 500,
      lineHeight: '150%',
    },
  };
};
