import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { CSS_VARIABLES } from '@shared/utils/layout';

export const initializeCssBaselineOverrides = (config?: Components<Theme>['MuiCssBaseline']) => {
  const {
    colorPrimary100,
    colorPrimary200,
    colorPrimary300,
    colorPrimary400,
    colorPrimary500,
    colorPrimary600,

    colorSecondary100,
    colorSecondary200,
    colorSecondary300,
    colorSecondary400,
    colorSecondary500,
    colorSecondary600,

    colorWarning100,
    colorWarning200,
    colorWarning300,
    colorWarning400,
    colorWarning500,
    colorWarning600,

    colorError100,
    colorError200,
    colorError300,
    colorError400,
    colorError500,
    colorError600,

    colorSuccess100,
    colorSuccess200,
    colorSuccess300,
    colorSuccess400,
    colorSuccess500,
    colorSuccess600,

    colorGrey50,
    colorGrey100,
    colorGrey200,
    colorGrey300,
    colorGrey400,

    colorGrey500,
    colorGrey600,
    colorGrey700,
    colorGrey800,
    colorGrey900,

    colorAdditionalWhite,
    colorAdditionalGrape,
    colorAdditionalBlueLight,
    colorAdditionalError,

    textSecondaryColor,
    disabledColor,
    accentColor,
    accentColorHover,
    textInputsColor,
    backgroundMainColor,
    errorColor,
    textTitleColor,
    textSubTitlesColor,
    mainColor,
    backgroundControlsColor,
    backgroundEmptyColor,
    backgroundOverlayColor,
    dashboardWidth,
    headerHeight,

    menuWidth,
    menuWidthMinimized,

    mainError,
    mainSuccess,

    bordersMainColor,

    controlDarkColor,
    controlDarkColorDisabled,
    controlDarkColorHover,
    controlDarkColorHoverBackground,
    controlLightColor,
    controlLightColorHover,
    fontFamily,
  } = CSS_VARIABLES;

  return {
    ...config,
    styleOverrides: {
      ':root': {
        //New styles
        [fontFamily]: '"Urbanist"',
        [colorPrimary100]: '#F7F9FC',
        [colorPrimary200]: '#DDDFE4',
        [colorPrimary300]: '#8E94A4',
        [colorPrimary400]: '#515973',
        [colorPrimary500]: '#1F2A4B',
        [colorPrimary600]: '#061237',

        [colorSecondary100]: '#9670FF',
        [colorSecondary200]: '#6B39F4',
        [colorSecondary300]: '#3C17B6',
        [colorSecondary400]: '#382085',
        [colorSecondary500]: '#4277FF',
        [colorSecondary600]: '#1355FF',

        [colorWarning100]: '#FFFCF1',
        [colorWarning200]: '#FFF9E2',
        [colorWarning300]: '#FFF2C5',
        [colorWarning400]: '#FFECA8',
        [colorWarning500]: '#FFE58B',
        [colorWarning600]: '#FFDF6E',

        [colorError100]: '#FEEAEF',
        [colorError200]: '#FFD4CC',
        [colorError300]: '#FAB0A2',
        [colorError400]: '#F38F7C',
        [colorError500]: '#EF3411',
        [colorError600]: '#D31C00',

        [colorSuccess100]: '#BEF9C0',
        [colorSuccess200]: '#78D07B',
        [colorSuccess300]: '#2ECE35',
        [colorSuccess400]: '#409944',
        [colorSuccess500]: '#409944',
        [colorSuccess600]: '#336A36',

        [colorGrey50]: '#F7F9FC',
        [colorGrey100]: '#E3E4E8',
        [colorGrey200]: '#C1C4CD',
        [colorGrey300]: '#9BA0AF',
        [colorGrey400]: '#83899B',

        [colorGrey500]: '#6A7187',
        [colorGrey600]: '#515973',
        [colorGrey700]: '#38415F',
        [colorGrey800]: '#1E2848',
        [colorGrey900]: '#061237',

        [colorAdditionalWhite]: '#FFFFFF',
        [colorAdditionalGrape]: '#7214FF',
        [colorAdditionalBlueLight]: '#00B3FF',
        [colorAdditionalError]: 'FF3D00',

        //Old styles
        [accentColor]: '#4162EA',
        [accentColorHover]: '#4162EA',
        [disabledColor]: '#BCC0C7',
        [errorColor]: '#FF2C2C',
        [mainColor]: '#323232',
        [textTitleColor]: '#000000',
        [textSecondaryColor]: '#505153',
        [textSubTitlesColor]: '#79818F',
        [textInputsColor]: '#BCC0C7',
        [backgroundMainColor]: '#FAF9FC',
        [backgroundControlsColor]: '#EDF1F3',
        [backgroundOverlayColor]: '#000000',
        [backgroundEmptyColor]: '#2D2C3E',
        [dashboardWidth]: '1366px',
        [headerHeight]: '50px',
        [mainError]: '#FF2C2C',
        [mainSuccess]: '#1FAE70',
        [bordersMainColor]: '#473763',
        [controlDarkColor]: '#323232',
        [controlDarkColorHover]: '#000000',
        [controlDarkColorHoverBackground]: '#EEEEEE',
        [controlDarkColorDisabled]: '#BCC0C7',
        [controlLightColor]: '#4162EA',
        [controlLightColorHover]: '#738EFE',
        //[fontFamily]: '"Roboto Mono", monospace, Arial, sans-serif, serif',
        [menuWidth]: '152px',
        [menuWidthMinimized]: '93px',
      },
      '#root': {
        minHeight: '100%',
      },
      a: {
        cursor: 'pointer',
      },
      input: {
        '&::-ms-clear, &::-ms-reveal': {
          display: 'none',
        },
      },
    },
  };
};
