import { AppPalette } from '@core/theme/types/main';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const initializeOutlinedInputOverrides = (
  palette: AppPalette,
  config?: Components<Theme>['MuiOutlinedInput'],
) => {
  return {
    ...config,
    root: {},
    styleOverrides: {
      root: {},
      notchedOutline: {
        borderColor: '#fff !important',
        borderWidth: '1px !important',
        borderRadius: '12px',

        '.MuiOutlinedInput-root &': {
          borderColor: '#fff',
          borderWidth: '1px',
          borderRadius: '12px',
        },
        '.MuiOutlinedInput-root:hover &': {
          borderColor: '#fff',
          borderWidth: '1px',
        },
        '.MuiOutlinedInput-root.Mui-focused &': {
          borderColor: '#fff',
          borderWidth: '1px',
        },
      },
      input: {
        color: '#fff',
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 33px #14101f inset !important`,
          '-webkit-text-fill-color': '#fff !important',
        },
        '&:-internal-autofill-selected': {
          backgroundColor: '##14101f !important',
          color: '#fff !important',
        },
      },
    },
  };
};
