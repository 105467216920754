import { t } from '@lingui/macro';
import { sanitizedAmount } from '@shared/utils/metals';
import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from 'class-validator';

@ValidatorConstraint({ name: 'amount', async: false })
export class Amount implements ValidatorConstraintInterface {
  validate(amount: string, args: ValidationArguments) {
    return sanitizedAmount(amount) > 0;
  }

  defaultMessage(args: ValidationArguments) {
    return t`This field is mandatory`;
  }
}
